import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/pteen/_src/a4i/website/src/components/layout.js";
import oauth from 'assets/oauth.png';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`What this app does`}</h1>
    <p>{`Analytics for Intercom is a one click integration to get reports on your Intercom data. There are out of the box dashboards (like Acquisition, Engagement) and DIY explorers to explore the data yourself (like Segments Explorer).
​`}</p>
    <h1>{`How to get started with the app`}</h1>
    <p>{`Getting started is mostly just a matter of installing the app and browsing around the charts :)`}</p>
    <ol>
      <li parentName="ol">{`Install the app: Go through the OAuth flow to give the app access restricted view access to your Intercom data. We request view access for your people data to plot the visualisations. For more information see below.`}</li>
    </ol>
    <img src={oauth} width={'70%'} />
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">
        <p parentName="li">{`Browse around: You'll automatically see visualisations.`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Some charts will take time to appear: We need time to accumulate data over time (like segment data) to get enough data to plot trends over time. Charts that need more time to be useful will have a disclaimer.`}</p>
      </li>
    </ol>
    <h1>{`Permissions breakdown`}</h1>
    <ul>
      <li parentName="ul">{`We request view permissions for users, companies, leads to plot our acquisition and engagement dashboards. Charts include new user trend over time, new company trend over time and so on.`}</li>
      <li parentName="ul">{`We request view permissions for events and attributes. This is for our events and attributes dashboards. Charts include seeing event count trend over time and attribute distribution across customers.`}</li>
      <li parentName="ul">{`We request view permissions for segments and tags for our segments explorer. Charts include seeinxg segment counts over time and segment retentions over time.`}</li>
      <li parentName="ul">{`We request view permissions for conversations for our conversation explorer. Charts include seeing conversations by revenue of account.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      